footer {
    height: 60px;
    margin-bottom: 12px;
}

footer a {
    color: white;
}

footer a:hover {
    color: rgb(0, 187, 135);
}

footer .footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    margin-bottom: 15px;
}

footer .footer-btns .footer-btn {
    cursor: pointer;
}

footer .footer-text p {
    font-size: 0.85rem;
}