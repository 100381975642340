header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .header-title, header .header-title .header-title-text {
    justify-content: flex-start;
    margin-left: 9px;
    padding: 0 5px 0 5px;
    font-size: 1.15rem;
    /* background-color: white; */
    color: white;
    text-decoration: none;
}

header .header-btns {
    display: flex;
    margin: 3px 27px 0 0;
    grid-gap: 35px;
    gap: 35px;
}

header .header-btns .header-btn {
    /* margin: 10px 15px; */
    /* background-color: #333; */
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
}

header .header-btns .header-btn.selected {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

header .header-btns .header-btn:hover, header .header-title .header-title-text:hover {
    cursor: pointer;
    color: rgb(0, 187, 135);
}
footer {
    height: 60px;
    margin-bottom: 12px;
}

footer a {
    color: white;
}

footer a:hover {
    color: rgb(0, 187, 135);
}

footer .footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 35px;
    gap: 35px;
    margin-bottom: 15px;
}

footer .footer-btns .footer-btn {
    cursor: pointer;
}

footer .footer-text p {
    font-size: 0.85rem;
}
.image-container {
    position: relative;
}

.image-container .image-overlay {
    position: absolute;
    display: flex;
    background-color: #080808;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    justify-content: center;
    align-items: center;
}

.image-container:hover .image-overlay {
    opacity: 0.7;
    cursor: pointer;
}

.image-container .image-overlay p {
    color: #fff;
}

.image-container p {
    text-align: right;
}

.image-container.right-image.bottom {
    transform: scaleY(1.03);
    transform-origin: top;
}

@media (min-width: 1200px) {
    .small-image-bottom-upper {
        margin-top: 17px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .small-image-bottom-upper {
        margin-top: 15px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .small-image-bottom-upper {
        margin-top: 16px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .small-image-bottom-upper {
        margin-top: 18px;
    }
}

@media (min-width: 1500px) and (max-width: 1900px) {
    .small-image-bottom-upper {
        margin-top: 20px;
    }
}

@media (min-width: 1900px) and (max-width: 2300px) {
    .small-image-bottom-upper {
        margin-top: 23px;
    }
}

@media (min-width: 2300px) {
    .small-image-bottom-upper {
        margin-top: 25px;
    }
}

@media (min-width: 992px) {
    .left-image {
        margin-right: 0px;
    }

    .right-image {
        margin-left: 0px;
    }

    .middle-row {
        padding-top: 18px;
    }

    .small-image-bottom {
        margin-top: 13px;
        transform: scaleY(0.98);
        transform-origin: top;
    }
}

@media (max-width: 991px) {
    .left-image, .right-image {
        margin-bottom: 30px;
    }

    .middle-row {
        padding-top: 0px;
    }

    .middle-row .left-image-col {
        margin-bottom: -47px;
    }

    .middle-row .left-image-bottom-col {
        margin-bottom: -30px;
    }

    .image-container .image-overlay {
        display: none;
    }

    .small-image-bottom {
        margin-top: 17px;
    }
}

@media (min-width: 1000px) {
    .about-container {
        padding-top: 12vh;
        /* padding-bottom: 10vh; */
        padding-left: 18vw;
        padding-right: 18vw;
    }
}

@media (max-width: 1000px) {
    .about-container {
        padding-top: 9vh;
        padding-bottom: 9vh;
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

.about-image-row {
    height: 40vh;
}

.about-container .about-image {
    max-width: 420px;
    margin: auto;
    padding-bottom: 70px;
}

.about-container .about-bio {
    text-align: left;
}

.about-container .about-reachout {
    text-align: center;
    padding-top: 30px;
}

@media (min-width: 1000px) {
    .watch-container {
        position: absolute;
        top: 10%;
        left: 15%;
        bottom: 0;
        right: 15%;
        margin-bottom: 20px;
        overflow: hidden;
    }
}

@media (max-width: 1000px) {
    .watch-container {
        position: absolute;
        top: 10%;
        left: 2vw;
        bottom: 0vh;
        right: 2vw;
        margin-bottom: 20px;
        overflow: hidden;
    }
}

.watch-container .iframe-embed {
    width: 100%;
    height: 70%;
    min-height: 250px;
}

.watch-container .watch-container-text {
    text-align: left;
    margin-bottom: 40px;
}

.App {
    background-color: #080808;
    font-family: Arial,
        "Helvetica Neue",
        Helvetica,
        sans-serif;
    margin: 0;
    text-align: center;
}

.App .main-container {
    margin: 0 14px 14px 14px;
    min-height: calc(100vh - 60px - 60px - 14px - 14px);
    position: relative;
}
html {
    background-color: #080808;
}

body {
    text-decoration: none;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 100;
    color: white;
}

b {
    font-weight: bold;
}

a {
    color: rgb(0, 187, 135);
    text-decoration: none;
}

a:hover {
    color: hotpink;
}
