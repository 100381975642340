@media (min-width: 1000px) {
    .about-container {
        padding-top: 12vh;
        /* padding-bottom: 10vh; */
        padding-left: 18vw;
        padding-right: 18vw;
    }
}

@media (max-width: 1000px) {
    .about-container {
        padding-top: 9vh;
        padding-bottom: 9vh;
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

.about-image-row {
    height: 40vh;
}

.about-container .about-image {
    max-width: 420px;
    margin: auto;
    padding-bottom: 70px;
}

.about-container .about-bio {
    text-align: left;
}

.about-container .about-reachout {
    text-align: center;
    padding-top: 30px;
}
