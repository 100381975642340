html {
    background-color: #080808;
}

body {
    text-decoration: none;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 100;
    color: white;
}

b {
    font-weight: bold;
}

a {
    color: rgb(0, 187, 135);
    text-decoration: none;
}

a:hover {
    color: hotpink;
}