@media (min-width: 1000px) {
    .watch-container {
        position: absolute;
        top: 10%;
        left: 15%;
        bottom: 0;
        right: 15%;
        margin-bottom: 20px;
        overflow: hidden;
    }
}

@media (max-width: 1000px) {
    .watch-container {
        position: absolute;
        top: 10%;
        left: 2vw;
        bottom: 0vh;
        right: 2vw;
        margin-bottom: 20px;
        overflow: hidden;
    }
}

.watch-container .iframe-embed {
    width: 100%;
    height: 70%;
    min-height: 250px;
}

.watch-container .watch-container-text {
    text-align: left;
    margin-bottom: 40px;
}
