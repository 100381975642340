header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .header-title, header .header-title .header-title-text {
    justify-content: flex-start;
    margin-left: 9px;
    padding: 0 5px 0 5px;
    font-size: 1.15rem;
    /* background-color: white; */
    color: white;
    text-decoration: none;
}

header .header-btns {
    display: flex;
    margin: 3px 27px 0 0;
    gap: 35px;
}

header .header-btns .header-btn {
    /* margin: 10px 15px; */
    /* background-color: #333; */
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
}

header .header-btns .header-btn.selected {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

header .header-btns .header-btn:hover, header .header-title .header-title-text:hover {
    cursor: pointer;
    color: rgb(0, 187, 135);
}