.image-container {
    position: relative;
}

.image-container .image-overlay {
    position: absolute;
    display: flex;
    background-color: #080808;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    justify-content: center;
    align-items: center;
}

.image-container:hover .image-overlay {
    opacity: 0.7;
    cursor: pointer;
}

.image-container .image-overlay p {
    color: #fff;
}

.image-container p {
    text-align: right;
}

.image-container.right-image.bottom {
    transform: scaleY(1.03);
    transform-origin: top;
}

@media (min-width: 1200px) {
    .small-image-bottom-upper {
        margin-top: 17px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .small-image-bottom-upper {
        margin-top: 15px;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .small-image-bottom-upper {
        margin-top: 16px;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .small-image-bottom-upper {
        margin-top: 18px;
    }
}

@media (min-width: 1500px) and (max-width: 1900px) {
    .small-image-bottom-upper {
        margin-top: 20px;
    }
}

@media (min-width: 1900px) and (max-width: 2300px) {
    .small-image-bottom-upper {
        margin-top: 23px;
    }
}

@media (min-width: 2300px) {
    .small-image-bottom-upper {
        margin-top: 25px;
    }
}

@media (min-width: 992px) {
    .left-image {
        margin-right: 0px;
    }

    .right-image {
        margin-left: 0px;
    }

    .middle-row {
        padding-top: 18px;
    }

    .small-image-bottom {
        margin-top: 13px;
        transform: scaleY(0.98);
        transform-origin: top;
    }
}

@media (max-width: 991px) {
    .left-image, .right-image {
        margin-bottom: 30px;
    }

    .middle-row {
        padding-top: 0px;
    }

    .middle-row .left-image-col {
        margin-bottom: -47px;
    }

    .middle-row .left-image-bottom-col {
        margin-bottom: -30px;
    }

    .image-container .image-overlay {
        display: none;
    }

    .small-image-bottom {
        margin-top: 17px;
    }
}
