.App {
    background-color: #080808;
    font-family: Arial,
        "Helvetica Neue",
        Helvetica,
        sans-serif;
    margin: 0;
    text-align: center;
}

.App .main-container {
    margin: 0 14px 14px 14px;
    min-height: calc(100vh - 60px - 60px - 14px - 14px);
    position: relative;
}